import publishFilter from './publish';

const sortAgenda = (agenda) => {
  const el = publishFilter(agenda, "agenda_publish")
  const ret = []

  let elSorted = []
  if (el.length > 1) {
    elSorted = el.sort((a, b) => {
      return (
        new Date(a.data.agenda_start_time) - new Date(b.data.agenda_start_time)
      )
    })
  } else { elSorted = el}

  let elGroup = []
  elSorted.forEach(el => {
    let d = el.data.agenda_start_time.substring(0, 10);
    if (elGroup.indexOf(d) === -1) {
      elGroup.push(d)
    }
  })

  const uuidArray = [];
  elGroup.forEach(d => {
    let e = {};
    e["date"] = d;
    e["data"] = [];

    elSorted.forEach(h => {
      let j = h.data.agenda_start_time.substr(0, 10)
      if (d === j && uuidArray.indexOf(h.recordId) === -1) {
        uuidArray.push(h.recordId)
        e["data"].push(h)
      }
    })

    ret.push(e)
  })
  
  return ret;
}

export default sortAgenda;